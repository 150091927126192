import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started-with-account-based-ticketing-integration"
    }}>{`Getting Started with Account Based Ticketing Integration`}</h1>
    <p>{`This guide is intended for system integrators and clients who wish to integrate with the Account Based Ticketing (ABT) solution. The documentation necessary for integration can be found on our web page:`}</p>
    <p><a parentName="p" {...{
        "href": "https://abt-developer-docs.dev.entur.io"
      }}>{`https://abt-developer-docs.dev.entur.io`}</a></p>
    <p>{`Please note that authentication is required to access this documentation.`}</p>
    <p><strong parentName="p">{`To gain access to the ABT integration documentation, please follow these steps:`}</strong></p>
    <ol>
      <li parentName="ol">{`Request access to the ABT documentation by contacting `}<a parentName="li" {...{
          "href": "id-basert-billettering@entur.org"
        }}>{`id-basert-billettering@entur.org`}</a>{`.`}</li>
      <li parentName="ol">{`Provide the necessary information such as email addresses of the main developers/system administrators, and email addresses of users that can download Android apps for testing purposes.`}</li>
      <li parentName="ol">{`Once your request is processed and you receive authentication, you can access the documentation on our web page.`}</li>
    </ol>
    <p>{`If you have any further questions or concerns about integrating with the ABT solution, please do not hesitate to contact us at `}<a parentName="p" {...{
        "href": "id-basert-billettering@entur.org"
      }}>{`id-basert-billettering@entur.org`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      